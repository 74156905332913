import { render, staticRenderFns } from "./FeedView.vue?vue&type=template&id=ac4d918a&scoped=true&"
import script from "./FeedView.vue?vue&type=script&lang=js&"
export * from "./FeedView.vue?vue&type=script&lang=js&"
import style0 from "./FeedView.vue?vue&type=style&index=0&id=ac4d918a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac4d918a",
  null
  
)

export default component.exports