import { render, staticRenderFns } from "./IconQuality.vue?vue&type=template&id=523780b3&"
import script from "./IconQuality.vue?vue&type=script&lang=js&"
export * from "./IconQuality.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports