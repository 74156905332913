import { render, staticRenderFns } from "./FollowButton.vue?vue&type=template&id=05e3acff&scoped=true&"
import script from "./FollowButton.vue?vue&type=script&lang=js&"
export * from "./FollowButton.vue?vue&type=script&lang=js&"
import style0 from "./FollowButton.vue?vue&type=style&index=0&id=05e3acff&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05e3acff",
  null
  
)

export default component.exports