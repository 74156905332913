import { render, staticRenderFns } from "./SophiePictureContestView.vue?vue&type=template&id=fac88512&scoped=true&"
import script from "./SophiePictureContestView.vue?vue&type=script&lang=js&"
export * from "./SophiePictureContestView.vue?vue&type=script&lang=js&"
import style0 from "./SophiePictureContestView.vue?vue&type=style&index=0&id=fac88512&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fac88512",
  null
  
)

export default component.exports