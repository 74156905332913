import { library } from '@fortawesome/fontawesome-svg-core';
import { faCreativeCommons } from '@fortawesome/free-brands-svg-icons/faCreativeCommons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faCircle as faCircleRegular } from '@fortawesome/free-regular-svg-icons/faCircle';
import { faClock as faClockRegular } from '@fortawesome/free-regular-svg-icons/faClock';
import { faHourglass as faHourglassRegular } from '@fortawesome/free-regular-svg-icons/faHourglass';
import { faTrashAlt as faTrashAltRegular } from '@fortawesome/free-regular-svg-icons/faTrashAlt';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons/faArrowsAltV';
import { faAt } from '@fortawesome/free-solid-svg-icons/faAt';
import { faAtlas } from '@fortawesome/free-solid-svg-icons/faAtlas';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faBold } from '@fortawesome/free-solid-svg-icons/faBold';
import { faBomb } from '@fortawesome/free-solid-svg-icons/faBomb';
import { faBroom } from '@fortawesome/free-solid-svg-icons/faBroom';
import { faBug } from '@fortawesome/free-solid-svg-icons/faBug';
import { faBullseye } from '@fortawesome/free-solid-svg-icons/faBullseye';
import { faBus } from '@fortawesome/free-solid-svg-icons/faBus';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faCloud } from '@fortawesome/free-solid-svg-icons/faCloud';
import { faCode } from '@fortawesome/free-solid-svg-icons/faCode';
import { faColumns } from '@fortawesome/free-solid-svg-icons/faColumns';
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment';
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments';
import { faCompass } from '@fortawesome/free-solid-svg-icons/faCompass';
import { faCompress } from '@fortawesome/free-solid-svg-icons/faCompress';
import { faDatabase } from '@fortawesome/free-solid-svg-icons/faDatabase';
import { faDirections } from '@fortawesome/free-solid-svg-icons/faDirections';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons/faEllipsisVertical';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons/faFlagCheckered';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { faGears } from '@fortawesome/free-solid-svg-icons/faGears';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons/faGlobeAmericas';
import { faGrin } from '@fortawesome/free-solid-svg-icons/faGrin';
import { faHashtag } from '@fortawesome/free-solid-svg-icons/faHashtag';
import { faHeading } from '@fortawesome/free-solid-svg-icons/faHeading';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faItalic } from '@fortawesome/free-solid-svg-icons/faItalic';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup';
import { faLifeRing } from '@fortawesome/free-solid-svg-icons/faLifeRing';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faListOl } from '@fortawesome/free-solid-svg-icons/faListOl';
import { faListUl } from '@fortawesome/free-solid-svg-icons/faListUl';
import { faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons/faLocationCrosshairs';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faMap } from '@fortawesome/free-solid-svg-icons/faMap';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkedAlt';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons/faNewspaper';
import { faObjectGroup } from '@fortawesome/free-solid-svg-icons/faObjectGroup';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faPlug } from '@fortawesome/free-solid-svg-icons/faPlug';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons/faRedoAlt';
import { faRobot } from '@fortawesome/free-solid-svg-icons/faRobot';
import { faRotate } from '@fortawesome/free-solid-svg-icons/faRotate';
import { faRoute } from '@fortawesome/free-solid-svg-icons/faRoute';
import { faRuler } from '@fortawesome/free-solid-svg-icons/faRuler';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons/faShareAlt';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faSortAmountDown } from '@fortawesome/free-solid-svg-icons/faSortAmountDown';
import { faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons/faSortAmountDownAlt';
import { faSortAmountUp } from '@fortawesome/free-solid-svg-icons/faSortAmountUp';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faSun } from '@fortawesome/free-solid-svg-icons/faSun';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag';
import { faTh } from '@fortawesome/free-solid-svg-icons/faTh';
import { faThLarge } from '@fortawesome/free-solid-svg-icons/faThLarge';
import { faThList } from '@fortawesome/free-solid-svg-icons/faThList';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faUndoAlt } from '@fortawesome/free-solid-svg-icons/faUndoAlt';
import { faUnlock } from '@fortawesome/free-solid-svg-icons/faUnlock';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons/faUserCheck';
import { faUserLock } from '@fortawesome/free-solid-svg-icons/faUserLock';
import { faUserShield } from '@fortawesome/free-solid-svg-icons/faUserShield';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

import activityHiking from '@/assets/font-awesome-custom/js/activity/hiking';
import activityIce_climbing from '@/assets/font-awesome-custom/js/activity/ice_climbing';
import activityMountain_biking from '@/assets/font-awesome-custom/js/activity/mountain_biking';
import activityMountain_climbing from '@/assets/font-awesome-custom/js/activity/mountain_climbing';
import activityOther from '@/assets/font-awesome-custom/js/activity/other';
import activityParagliding from '@/assets/font-awesome-custom/js/activity/paragliding';
import activityRock_climbing from '@/assets/font-awesome-custom/js/activity/rock_climbing';
import activitySkitouring from '@/assets/font-awesome-custom/js/activity/skitouring';
import activitySlacklining from '@/assets/font-awesome-custom/js/activity/slacklining';
import activitySnow_ice_mixed from '@/assets/font-awesome-custom/js/activity/snow_ice_mixed';
import activitySnowshoeing from '@/assets/font-awesome-custom/js/activity/snowshoeing';
import activityVia_ferrata from '@/assets/font-awesome-custom/js/activity/via_ferrata';
import documentTypeOuting from '@/assets/font-awesome-custom/js/document-type/outing';
import drill from '@/assets/font-awesome-custom/js/miscs/drill';
import paragliding from '@/assets/font-awesome-custom/js/miscs/paragliding';
import sort from '@/assets/font-awesome-custom/js/miscs/sort';
import qualityDraft from '@/assets/font-awesome-custom/js/quality/draft';
import qualityEmpty from '@/assets/font-awesome-custom/js/quality/empty';
import qualityFine from '@/assets/font-awesome-custom/js/quality/fine';
import qualityGreat from '@/assets/font-awesome-custom/js/quality/great';
import qualityMedium from '@/assets/font-awesome-custom/js/quality/medium';
import waypointaccess from '@/assets/font-awesome-custom/js/waypoint/access';
import waypointbase_camp from '@/assets/font-awesome-custom/js/waypoint/base_camp';
import waypointbergschrund from '@/assets/font-awesome-custom/js/waypoint/bergschrund';
import waypointbisse from '@/assets/font-awesome-custom/js/waypoint/bisse';
import waypointbivouac from '@/assets/font-awesome-custom/js/waypoint/bivouac';
import waypointcamp_site from '@/assets/font-awesome-custom/js/waypoint/camp_site';
import waypointcanyon from '@/assets/font-awesome-custom/js/waypoint/canyon';
import waypointcave from '@/assets/font-awesome-custom/js/waypoint/cave';
import waypointcliff from '@/assets/font-awesome-custom/js/waypoint/cliff';
import waypointclimbing_indoor from '@/assets/font-awesome-custom/js/waypoint/climbing_indoor';
import waypointclimbing_outdoor from '@/assets/font-awesome-custom/js/waypoint/climbing_outdoor';
import waypointconfluence from '@/assets/font-awesome-custom/js/waypoint/confluence';
import waypointgite from '@/assets/font-awesome-custom/js/waypoint/gite';
import waypointglacier from '@/assets/font-awesome-custom/js/waypoint/glacier';
import waypointhut from '@/assets/font-awesome-custom/js/waypoint/hut';
import waypointlake from '@/assets/font-awesome-custom/js/waypoint/lake';
import waypointlocal_product from '@/assets/font-awesome-custom/js/waypoint/local_product';
import waypointlocality from '@/assets/font-awesome-custom/js/waypoint/locality';
import waypointmisc from '@/assets/font-awesome-custom/js/waypoint/misc';
import waypointparagliding_landing from '@/assets/font-awesome-custom/js/waypoint/paragliding_landing';
import waypointparagliding_takeoff from '@/assets/font-awesome-custom/js/waypoint/paragliding_takeoff';
import waypointpass from '@/assets/font-awesome-custom/js/waypoint/pass';
import waypointpit from '@/assets/font-awesome-custom/js/waypoint/pit';
import waypointshelter from '@/assets/font-awesome-custom/js/waypoint/shelter';
import waypointslackline_spot from '@/assets/font-awesome-custom/js/waypoint/slackline_spot';
import waypointsummit from '@/assets/font-awesome-custom/js/waypoint/summit';
import waypointvirtual from '@/assets/font-awesome-custom/js/waypoint/virtual';
import waypointwaterfall from '@/assets/font-awesome-custom/js/waypoint/waterfall';
import waypointwaterpoint from '@/assets/font-awesome-custom/js/waypoint/waterpoint';
import waypointwaypoints from '@/assets/font-awesome-custom/js/waypoint/waypoints';
import waypointweather_station from '@/assets/font-awesome-custom/js/waypoint/weather_station';
import waypointwebcam from '@/assets/font-awesome-custom/js/waypoint/webcam';

// registered globally
export default function install(Vue) {
  library.add(
    drill,
    paragliding,
    sort,

    documentTypeOuting,

    waypointaccess,
    waypointbase_camp,
    waypointbergschrund,
    waypointbisse,
    waypointbivouac,
    waypointcamp_site,
    waypointcanyon,
    waypointcave,
    waypointcliff,
    waypointclimbing_indoor,
    waypointclimbing_outdoor,
    waypointconfluence,
    waypointgite,
    waypointglacier,
    waypointhut,
    waypointlake,
    waypointlocality,
    waypointlocal_product,
    waypointmisc,
    waypointparagliding_landing,
    waypointparagliding_takeoff,
    waypointpass,
    waypointpit,
    waypointshelter,
    waypointslackline_spot,
    waypointsummit,
    waypointvirtual,
    waypointwaterfall,
    waypointwaterpoint,
    waypointwaypoints,
    waypointweather_station,
    waypointwebcam,

    activityHiking,
    activityIce_climbing,
    activityOther,
    activityMountain_biking,
    activityMountain_climbing,
    activityParagliding,
    activityRock_climbing,
    activitySkitouring,
    activitySlacklining,
    activitySnowshoeing,
    activitySnow_ice_mixed,
    activityVia_ferrata,

    qualityEmpty,
    qualityDraft,
    qualityMedium,
    qualityFine,
    qualityGreat,

    // solid icons
    faAngleDown,
    faArrowsAltV,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faAt,
    faAtlas,
    faBan,
    faBars,
    faBold,
    faBomb,
    faBroom,
    faBug,
    faBus,
    faBullseye,
    faCalendar,
    faCamera,
    faChartBar,
    faCheck,
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronDown,
    faCircle,
    faCloud,
    faCode,
    faColumns,
    faComment,
    faComments,
    faCompass,
    faCompress,
    faDatabase,
    faDirections,
    faDownload,
    faEdit,
    faEllipsisVertical,
    faExclamation,
    faExclamationCircle,
    faExpand,
    faExternalLinkAlt,
    faEye,
    faEyeSlash,
    faFileDownload,
    faFilter,
    faFlag,
    faFlagCheckered,
    faGear,
    faGears,
    faGlobe,
    faGlobeAmericas,
    faGrin,
    faHashtag,
    faHeading,
    faHeart,
    faHistory,
    faHome,
    faImage,
    faInfo,
    faInfoCircle,
    faItalic,
    faKey,
    faLayerGroup,
    faLifeRing,
    faLink,
    faList,
    faListOl,
    faListUl,
    faLock,
    faLocationCrosshairs,
    faMap,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faMinus,
    faNewspaper,
    faObjectGroup,
    faPen,
    faPlug,
    faPlus,
    faPlusCircle,
    faPrint,
    faQuestionCircle,
    faRedoAlt,
    faRobot,
    faRotate,
    faRoute,
    faRuler,
    faSearch,
    faShareAlt,
    faSignInAlt,
    faSignOutAlt,
    faSortAmountDown,
    faSortAmountDownAlt,
    faSortAmountUp,
    faSquare,
    faStar,
    faSun,
    faTachometerAlt,
    faTag,
    faTh,
    faThLarge,
    faThList,
    faTrash,
    faUndoAlt,
    faUnlock,
    faUpload,
    faUser,
    faUserCheck,
    faUserLock,
    faUsers,
    faUserShield,
    faWrench,

    // regular icons
    faCircleRegular,
    faClockRegular,
    faHourglassRegular,
    faTrashAltRegular,

    // brands icons
    faCreativeCommons,
    faFacebook,
    faGoogle,
    faInstagram,
    faYoutube
  );

  Vue.component('FaIcon', FontAwesomeIcon);
  Vue.component('FaLayers', FontAwesomeLayers);
}
