import { render, staticRenderFns } from "./CardRow.vue?vue&type=template&id=1b1e1106&scoped=true&"
var script = {}
import style0 from "./CardRow.vue?vue&type=style&index=0&id=1b1e1106&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b1e1106",
  null
  
)

export default component.exports