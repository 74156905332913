var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"has-text-centered"},[(_vm.license === 'by-sa')?_c('a',{attrs:{"href":'https://creativecommons.org/licenses/by-sa/3.0/deed.' + _vm.$language.current,"target":"_blank","rel":"noreferer"}},[_c('icon-creative-commons'),_vm._v("\n    CC\n    "),_c('span',{attrs:{"title":_vm.$gettext(
          'Attribution — You must give appropriate credit, provide a link to the license, and indicate if changes were made.'
        )}},[_vm._v("\n      BY\n    ")]),_vm._v(" "),_c('span',{attrs:{"title":_vm.$gettext(
          'ShareAlike — If you remix, transform, or build upon the material, you must distribute your contributions under the same license as the original.'
        )}},[_vm._v("\n      SA\n    ")]),_vm._v("\n    3.0\n    "),_c('br'),_vm._v(" "),_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v(" This content is licensed under Creative Commons BY-SA 3.0 ")])],1):(_vm.license == 'by-nc-nd')?_c('a',{attrs:{"href":'https://creativecommons.org/licenses/by-nc-nd/3.0/deed.' + _vm.$language.current,"target":"_blank","rel":"noreferer"}},[_c('icon-creative-commons'),_vm._v("\n    CC\n    "),_c('span',{attrs:{"title":_vm.$gettext(
          'Attribution — You must give appropriate credit, provide a link to the license, and indicate if changes were made.'
        )}},[_vm._v("\n      BY\n    ")]),_vm._v(" "),_c('span',{attrs:{"title":_vm.$gettext('NonCommercial — You may not use the material for commercial purposes.')}},[_vm._v(" NC ")]),_vm._v(" "),_c('span',{attrs:{"title":_vm.$gettext(
          'NoDerivatives — If you remix, transform, or build upon the material, you may not distribute the modified material.'
        )}},[_vm._v("\n      ND\n    ")]),_vm._v("\n    3.0\n    "),_c('br'),_vm._v(" "),_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v(" This content is licensed under Creative Commons BY-NC-ND 3.0 ")])],1):(_vm.license == 'copyright')?_c('span',{attrs:{"title":_vm.$gettext(
        'This picture depicts a book cover. It is the property of its editor and/or author. It is presented here only for illustration purposes.'
      )}},[_c('fa-icon',{attrs:{"icon":"ban"}}),_vm._v(" "),_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v(" This book cover is the property of its editor and/or author ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }